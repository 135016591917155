import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation])

export default class extends Controller {

  connect () {
    new Swiper(this.element, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 48,
      autoplay: {
        delay: 10000
      },

      breakpoints: {
        1240: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 2,
        }
      }
    })
  }
}