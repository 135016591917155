import { Controller } from 'stimulus'

export default class extends Controller{
  static targets = []

  connect() {
    this.isScrolled()
    document.addEventListener('scroll', () => {
      this.isScrolled()
    })
  }

  isScrolled() {
    if (window.pageYOffset > 0) {
      this.element.classList.add('scrolled')
    } else {
      this.element.classList.remove('scrolled')
    }
  }
}