import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation, Pagination, EffectFade, Controller as SwiperController } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation, Pagination, EffectFade, SwiperController])

export default class extends Controller {
  static targets = ['next', 'prev', 'titleContainer', 'contentContainer']

  connect () {
    const titleSwiper = new Swiper(this.titleContainerTarget, {
      slidesPerView: 1,
      spaceBetween: 16,
      initialSlide:5,
      centeredSlides: true,
      slideToClickedSlide: true,
      speed: 400,
      breakpoints: {
        540: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3
        },
        1240: {
          slidesPerView: 5
        }
      },
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget
      }
    })

    const contentSwiper = new Swiper(this.contentContainerTarget, {
      initialSlide: 5,
      slidesPerView: 1,
      centeredSlides: true,
      effect: 'fade',
      preventClicks: true,
      allowTouchMove: false,
      fadeEffect: {
        crossFade: true
      }
    })

    titleSwiper.on('slideChange', function() {
      contentSwiper.slideTo(titleSwiper.activeIndex, 400, true)
    })

    contentSwiper.on('slideChange', function() {
      console.log("slide is currently" + contentSwiper.activeIndex)
    })
  }
}
