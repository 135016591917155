import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import SweetScroll from 'sweet-scroll'

import 'alpinejs'
import 'tailwindcss/tailwind.css'

import 'controllers'

require.context('../images', true)

Rails.start()
ActiveStorage.start()
Turbolinks.start()


document.addEventListener('turbolinks:render', () => {
    const scroller = new SweetScroll();
    const hash = window.location.hash;
    const needsInitialScroll = document.getElementById(hash.substr(1)) != null;

    if (needsInitialScroll) {
      window.location.hash = '';
    }

    setTimeout(() => {
      if (needsInitialScroll) {
        scroller.to(hash, { updateURL: 'replace' });
      }
    }, 500);
  },
  false,
);